.carousel-container {
  border-radius: 0;
  padding: 0;
}

@media (min-width: 1200px) {
  .carousel-container {
    border-radius: 8px;
    padding: 0 4px;
  }
}

.item {
  background-color: black;
  display: flex !important;
  align-items: center;
  height: 50vh;
}

.item img {
  margin: 0 auto;
  max-height: 100%;
  max-width: 100vw;
}
