.title-link {
  font-size: 1.125rem;
  font-weight: 500;
  /* text-decoration: none; */
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  margin: 0;
  background: #1677ff;
  background: -webkit-linear-gradient(to right, #0575E6, #1677ff);
  background: linear-gradient(to right, #0575E6, #1677ff);
}

header .ant-typography,
header svg {
  color: white;
}

header .ant-btn-icon {
  vertical-align: text-top;
}

@media (min-width: 1200px) {
  header {
    padding: 16px 32px !important;
    margin: 24px 0 !important;
    border-radius: 8px;
  }
}
